<template>
  <section class="text-xl overflow-hidden">
    <Container>
      <div class="block lg:grid lg:grid-cols-2 xl:grid-cols-3">
        <div class="xl:col-span-2 py-12">
          <div
            class="
              uppercase
              text-pink-600 text-xs
              sm:text-sm
              tracking-widest
              mb-2
              md:mb-6
            "
          >
            digital health
          </div>
          <div
            class="
              text-2xl
              md:text-4xl
              xl:text-7xl
              font-light
              tracking-wide
              max-w-4xl
              mb-4
              md:mb-8
            "
          >
            Новое поколение медицины
          </div>
          <div
            class="
              text-xl
              xl:text-3xl
              font-light
              max-w-sm
              xl:max-w-xl
              leading-8
              xl:leading-8
              mb-8
              md:mb-11
            "
          >
            Высокие технологии, доказательные врачи и лечение в игровой форме.
          </div>

          <MarkList class="max-w-sm xl:max-w-2xl" :list="advantages" />

          <NuxtLink
            to="/about"
            class="
              inline-flex
              text-zinc-800
              md:text-base
              text-sm
              uppercase
              justify-center
              bg-pink-100
              rounded-full
              py-4
              md:py-6 md:px-10
              px-8
              mb
              md:mb-0
              font-light
              md:tracking-wider
            "
            >подробнее о клинике
          </NuxtLink>
        </div>
        <div
          class="
            relative
            -ml-6
            w-[100vw]
            lg:w-full lg:ml-auto
            max-h-screen
            lg:max-h-full
          "
        >
          <div
            class="
              swiper-section swiper
              static
              xl:absolute
              left-0
              top-0
              bottom-0
              h-full
              w-full
              max-w-full max-h-screen
              lg:max-h-full
              xl:w-[38vw]
              lg:w-[50vw] lg:max-w-[625px]
            "
          >
            <!-- Additional required wrapper -->
            <div class="swiper-wrapper">
              <!-- Slides -->
              <div class="swiper-slide w-full">
                <img
                  src="@/assets/img/index/2.webp"
                  class="h-full w-full object-cover max-w-full swiper-lazy"
                  alt=""
                />
                <div
                  class="
                    w-full
                    swiper-lazy-preloader swiper-lazy-preloader-white
                  "
                ></div>
              </div>
              <div class="swiper-slide">
                <img
                  src="@/assets/img/index/1.webp"
                  loading="lazy"
                  class="h-full w-full object-cover max-w-full swiper-lazy"
                  alt=""
                />
                <div
                  class="
                    w-full
                    swiper-lazy-preloader swiper-lazy-preloader-white
                  "
                ></div>
              </div>
              <div class="swiper-slide">
                <img
                  src="@/assets/img/index/3.webp"
                  loading="lazy"
                  class="h-full w-full object-cover max-w-full swiper-lazy"
                  alt=""
                />
                <div
                  class="
                    w-full
                    swiper-lazy-preloader swiper-lazy-preloader-white
                  "
                ></div>
              </div>
            </div>
            <!-- If we need pagination -->
            <div class="swiper-pagination"></div>
          </div>
        </div>
      </div>
    </Container>
  </section>
</template>
<script setup>
import MarkList from "../UI/MarkList.vue";
import Swiper, { Pagination, Lazy } from "swiper";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/lazy";

let engine;

onMounted(() => {
  Swiper.use([Pagination, Lazy]);

  const swiperOptions = {
    loop: true,
    slidesPerView: 1,
    pagination: {
      el: ".swiper-section .swiper-pagination",
      type: "bullets",
    },
    preloadImages: false,
    lazyLoading: true,
    lazy: {
      checkInView: true,
    },
  };

  nextTick(() => {
    engine = new Swiper(".swiper-section.swiper", swiperOptions);
  });
});

onBeforeUnmount(() => {
  try {
    engine?.destroy?.();
  } catch (err) {
    console.log(err);
  }
});

const advantages = [
  {
    id: 1,
    title:
      "100% внимания ребенку - общаемся с малышами и тинейджерами на равных, не задаем неловких вопросов, отвечаем просто и подробно на все, что интересно узнать.",
  },
  {
    id: 2,
    title:
      "Иммерсивные технологии, VR, интерактивная игровая зона. Можно даже попробовать радугу на вкус.",
  },
  {
    id: 3,
    title:
      "Безопасные игровые методики и доказательная медицина. Не назначаем лишнего и остаемся на связи после приема. А еще покажем, где обитают Фоминчики.",
  },
  {
    id: 4,
    title:
      "Высокий уровень сервиса и медицины благодаря IT разработкам. Запись к врачу в 1 клик, единый профиль для всей семьи, внедрение ИИ для решения рутинных задач.",
  },
];
</script>
